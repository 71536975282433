<template>
  <div class="test">
    <div class="test__body">
      <div class="test__progress">
        <div
          class="test__progress-item"
          :class="{ 'test__progress-item_active': activeQuestion >= q - 1 }"
          v-for="q in questions.length"
          :key="q"
        >
          <span>{{ q }}</span>
        </div>
      </div>
      <div
        class="test__question-title"
        v-html="questions[activeQuestion].title"
      ></div>
      <div
        class="test__question-description mb-5"
        v-if="questions[activeQuestion].description"
        v-html="questions[activeQuestion].description"
      ></div>
      <div
        class="test__question-answers"
        :class="{ 'test__question-answers_disabled': currentTip }"
      >
        <label
          class="test__question-answer"
          v-for="(answer, aInd) in questions[activeQuestion].answers"
          :key="aInd"
        >
          <input
            :value="`${aInd + 1}`"
            :type="
              questions[activeQuestion].type === 'once' ? 'radio' : 'checkbox'
            "
            v-model="choices[activeQuestion]"
          />
          <div class="test__question-answer-container">
            <div
              class="test__question-answer-radio"
              v-if="questions[activeQuestion].type === 'once'"
            >
              <div></div>
            </div>
            <div class="test__question-answer-check" v-else>
              <div>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5196 0C10.1238 0 9.75184 0.162293 9.47191 0.456982L3.98971 6.23613L2.52856 4.69847C2.24881 4.40398 1.87686 4.24176 1.48122 4.24176C1.08552 4.24176 0.713561 4.40398 0.433753 4.6984C0.154011 4.99282 0 5.38428 0 5.80067C0 6.21706 0.154011 6.60853 0.433753 6.90288L2.94257 9.54329C3.22231 9.83778 3.59427 10 3.98997 10C4.38548 10 4.75757 9.83771 5.03763 9.54302L11.567 2.66132C12.1444 2.05342 12.1443 1.06441 11.567 0.456505C11.2872 0.162157 10.9152 0 10.5196 0Z"
                    fill="#D0006F"
                  />
                </svg>
              </div>
            </div>
            <div class="test__question-answer-text" v-html="answer"></div>
          </div>
        </label>
      </div>
      <div
        class="button button_empty-pink-light px-8 mt-6"
        @click="getConditionIndex(activeQuestion)"
        v-if="!currentTip"
      >
        Проверить ответ
      </div>
      <div
        class="button button_pink-light px-8 mt-6"
        @click="nextQuestion"
        v-if="
          currentTip &&
          currentTip.status === true &&
          activeQuestion < questions.length - 1
        "
      >
        Следующий вопрос
      </div>
      <div
        class="button button_pink-light px-8 mt-6"
        @click="refreshTest"
        v-if="
          currentTip &&
          (currentTip.status !== true || activeQuestion == questions.length - 1)
        "
      >
        Попробовать ещё раз
      </div>
    </div>
    <div
      class="test__status"
      v-if="currentTip"
      :style="`background-color: ${statusColor}`"
    >
      <div class="test__status-title" v-html="currentTip.title"></div>
      <div class="test__status-body" v-html="currentTip.body"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Test",
  props: {
    questions: Array,
    tips: Array,
  },
  data: () => ({
    choices: [],
    activeQuestion: 0,
    questionsResults: [],
    currentTip: null,
  }),
  computed: {
    statusColor() {
      if (!this.currentTip) {
        return false;
      }
      if (this.currentTip.status === true) {
        return "#6BC399";
      }
      if (this.currentTip.status === false) {
        return "#CF1517";
      }
      if (this.currentTip.status === null) {
        return "#F0AB00";
      }
      return false;
    },
  },
  methods: {
    async fillAnswers() {
      new Promise((resolve, reject) => {
        for (let qInd in this.questions) {
          if (this.questions[qInd].type === "once") {
            this.$set(this.choices, `${qInd}`, null);
          } else {
            this.$set(this.choices, `${qInd}`, []);
          }
        }
        resolve(true);
      });
    },
    getConditionIndex(qInd) {
      const tip = this.tips[qInd].find(
        (el) =>
          el.conditions.findIndex(
            (c) =>
              JSON.stringify(c) ===
              JSON.stringify([...this.choices[qInd]].sort())
          ) > -1
      );
      this.$set(this, "currentTip", tip);
    },
    nextQuestion() {
      this.activeQuestion++;
      this.$set(this, "currentTip", null);
    },
    refreshTest() {
      this.$emit("refreshTest");
    },
  },
  created() {
    this.fillAnswers();
  },
};
</script>

<style lang="scss" scoped>
.test {
  margin-top: 20px;

  border-radius: 30px;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 40px 50px -30px #00000059;
  background-color: #fff;

  &__body {
    padding: 30px;

    @media screen and (max-width: 767px) {
      padding: 20px;
    }
  }

  &__progress {
    margin-bottom: 30px;
    padding: 6px 0;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    background-color: #fae5f0;
    overflow: hidden;
    border-radius: 10px;

    &-item {
      position: relative;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: #ec99c5;
      display: flex;
      justify-content: center;
      align-items: center;

      & span {
        position: relative;
        z-index: 2;
      }

      &:not(:last-child) {

        &::after {
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          right: 0;
          display: block;
          content: "";
          background-color: #ec99c5;
        }
      }

      &_active {
        &::before {
          position: absolute;
          width: 100%;
          height: calc(100% + 12px);
          top: -6px;
          left: 0;
          display: block;
          content: "";
          background-color: #d0006f;
        }
        &::after {
          background-color: #EC99C5;
        }

        // margin: -6px 0; 
        color: #fff;
        font-weight: 600;
      }
    }
  }

  &__question {
    &-title {
      margin-bottom: 25px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-answers {
      &_disabled {
        pointer-events: none;
      }
    }

    &-answer {
      display: block;
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &-container {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &-radio {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #fff;
        border: 2px solid #1f1f1f;

        

        & > * {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          opacity: 0;
          background-color: #d0006f;
        }
      }

      &-check {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 5px;
        background-color: #fff;
        border: 2px solid #1f1f1f;

       

        & > * {
          width: 12px;
          height: 10px;
          opacity: 0;
        }
      }

      &-text {
        margin-left: 16px;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #1f1f1f;

        @media screen and (max-width: 767px) {
        font-size: 15px;
        line-height: 23px;
      }
      }

      & input {
        display: none;

        &:checked {
          & ~ .test__question-answer-container {
            & .test__question-answer {
              &-radio {
                border-color: #d0006f;
                & > * {
                  opacity: 1;
                }
              }

              &-check {
                border-color: #d0006f;
                & > * {
                  opacity: 1;
                }
              }

              &-text {
                color: #d0006f;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  &__status {
    padding: 25px 30px;
    border-radius: 0 0 30px 30px;

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    &-title {
      margin-bottom: 15px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;

      @media screen and (max-width: 767px) {
       font-size: 16px;
        line-height: 24px;
    }
    }

    &-body {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
    }
  }
}
</style>

<style lang="scss">
.test {
  & .overflow {
    &__body {
      padding-bottom: 15px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3c1053;
        border-radius: 5px;
        height: 8px;
      }

      &::-webkit-scrollbar--track-piece {
        border-radius: 5px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: #ccd3df;
        border: 3px solid transparent;
      }

      &::-moz-scrollbar-button:decrement,
      &::-moz-scrollbar-button:increment,
      &::-moz-scrollbar-button:vertical:start:decrement,
      &::-moz-scrollbar-button:vertical:end:increment,
      &::-moz-scrollbar-button:horizontal:start:decrement,
      &::-moz-scrollbar-button:horizontal:end:increment,
      &::-webkit-scrollbar-button:vertical:start:decrement,
      &::-webkit-scrollbar-button:vertical:end:increment,
      &::-webkit-scrollbar-button:horizontal:start:decrement,
      &::-webkit-scrollbar-button:horizontal:end:increment {
        width: 0px !important;
        height: 0px !important;
      }
    }

    & img {
      width: 1216px;

      @media screen and (max-width: 1219px) {
        width: 800px;
        max-width: none !important;
      }

      @media screen and (max-width: 767px) {
        width: 800px;
      }
    }
  }
}
.test {
  & img {
    max-width: 100%;
  }
}
.test__status-body {
  & b {
    font-weight: 700;
  }

  & a {
    text-decoration: underline;
  }

  & img {
    max-width: 100%;
  }

  

  & .full-screen {
    position: relative;

    &__trigger {
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 16px;
      right: 16px;
      z-index: 10;
      display: block;
    }
  }
}
</style>