<template>
  <section class="pages-slider">
    <VueSlickCarousel
      class="pages-slider__slider"
      ref="pagesSlider"
      @init="onInit"
      @beforeChange="beforeChange"
      @afterChange="afterChange"
      v-bind="settings"
    >
      <div class="top-block" v-for="(banner, ind) in banners" :key="ind">
        <div
          class="top-block__image-container"
          v-if="formattedImageList && formattedImageList.length"
        >
          <picture>
            <source
              v-for="(img, ind) in formattedImageList(banner)"
              :key="ind"
              :srcset="img.src"
              :media="`(max-width: ${img.media}px)`"
            />
            <img
              class="top-block__image"
              v-if="banner && banner.banner_desktop"
              :src="
                noHost
                  ? '' + banner.banner_desktop
                  : $root.host + banner.banner_desktop
              "
            />
          </picture>
        </div>
      </div>
    </VueSlickCarousel>
    <div class="first-slider__nav" v-if="slideCount > 1">
      <div class="content">
        <div class="first-slider__nav-row">
          <div class="first-slider__counter">
            {{ currentSlide }}/{{ slideCount }}
          </div>
          <div class="first-slider__arrows">
            <div
              class="first-slider__arrow first-slider__arrow_prev"
              :style="`color: ${color}`"
              @click="$refs.pagesSlider.prev()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.49691e-07 8L7 16L9.5 16L3.5 9L16 9L16 7L3.5 7L9.5 -2.84124e-07L7 -3.93402e-07L3.49691e-07 8Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div
              class="first-slider__arrow first-slider__arrow_next"
              :style="`color: ${color}`"
              @click="$refs.pagesSlider.next()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                  fill="currentColor"
                />
              </svg>
              <div
                class="first-slider__arrow-progress"
                :class="{ 'first-slider__arrow-progress_active': loadAnim }"
              >
                <svg
                  viewPort="0 0 100 100"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle :stroke="color" fill="transparent"></circle>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "PagesSlider",
  components: { VueSlickCarousel },
  props: {
    title: String,
    banners: Array,
    color: String,
    noHost: { default: false, type: Boolean },
  },
  data: () => ({
    currentSlide: 1,
    slideCount: 0,
    loadAnim: false,
    autoplayInterval: null,
    settings: {
      arrows: false,
      dots: false,
      draggable: false,
    },
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
  }),
  methods: {
    autoplay(time) {
      this.autoplayInterval = setInterval(() => {
        if (
          this.$refs &&
          this.$refs.pagesSlider &&
          typeof this.$refs.pagesSlider.next === "function"
        ) {
          this.$refs.pagesSlider.next();
        }
      }, time);
    },
    beforeChange(oldSlideIndex, newSlideIndex) {
      this.loadAnim = false;
      this.currentSlide = newSlideIndex + 1;
    },
    afterChange() {
      clearInterval(this.autoplayInterval);
      this.autoplay(5000);
      setTimeout(() => {
        this.loadAnim = true;
      }, 10);
    },
    onInit() {
      if (this.banners.length > 1) {
        this.autoplay(5000);
        this.loadAnim = true;
      }
    },
    formattedImageList(slide) {
      let imageList = [];
      let usedImages = Object.keys(slide)
        .filter((el) => el.includes("banner_"))
        .map((el) => el.replace("banner_", ""));
      for (let imgFormat of usedImages) {
        if (slide["banner_" + imgFormat]) {
          imageList.push({
            src: this.noHost
              ? "" + slide["banner_" + imgFormat].replace("http:", "https:")
              : this.$root.host +
                slide["banner_" + imgFormat].replace("http:", "https:"),
            media: this.media[imgFormat],
          });
        }
      }
      imageList.sort((a, b) => (a.media > b.media ? 1 : -1));
      return imageList;
    },
  },
  mounted() {
    this.slideCount = this.banners.length;
    // const descriptionBlock = this.$refs.descriptionBlock;
    // if (descriptionBlock.querySelector(".modify-parent")) {

    //   // Add any other styles you need to change here
    // }
  },
};
</script>

<style lang="scss" scoped>
.pages-slider {
  height: 100%;

  .top-block {
    position: relative;
    height: auto;

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &__image-container {
      // position: absolute;
      // top: 0;
      // left: 0;
      width: 100%;
      height: auto;

      @media screen and (max-width: 1220px) {
        // height: 400px;
      }

      @media screen and (max-width: 767px) {
        // height: 252px;
        position: static;
      }
    }
  }

  .content {
    height: 100%;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 32px 0;

    @media screen and (max-width: 1220px) {
      position: static;
      padding: 0;
      & .content {
        padding: 0;
      }
    }

    @media screen and (max-width: 767px) {
    }
  }

  &__block {
    height: 100%;
    width: 384px;
    margin-left: auto;
    background-color: #ebefee;
    @media screen and (max-width: 1220px) {
      width: 100%;
      padding-bottom: 36px;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 26px;
    }
  }

  &__slider {
    height: 100%;
    @media screen and (max-width: 1220px) {
      margin-bottom: 32px;
    }

    @media screen and (max-width: 767px) {
    }
  }

  &__slide {
    position: relative;
    padding: 32px;
    height: 100%;

    @media screen and (max-width: 1220px) {
      padding: 32px 76px;
    }

    @media screen and (max-width: 767px) {
      padding: 23px 16px 32px;
    }

    & > .content {
      height: 100%;
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      display: block;
    }

    &-text {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      height: 100%;

      @media screen and (max-width: 767px) {
        width: auto;
      }
    }

    &-tag {
      width: 47px;
      padding: 4px 8px;
      margin-bottom: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #3c4242;
      border: 1px solid #d8dada;
      border-radius: 99px;
    }

    &-caption {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #3c4242;

      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
        padding-right: 47px;
      }
    }

    &-subtitle {
      margin-bottom: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #3c4242;
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #656969;

      @media screen and (max-width: 1220px) {
        margin-top: 32px;
        width: 382px;
      }

      @media screen and (max-width: 767px) {
        width: auto;
        border-top: 0;
        margin-top: 16px;
        padding-top: 0;
      }
    }
  }

  &__nav {
    position: relative;
    z-index: 2;
    margin-top: -80px;
    & .content {
      width: 100%;
      padding: 0 32px;
      @media screen and (max-width: 1220px) {
        padding: 0 76px;
      }
      @media screen and (max-width: 767px) {
        padding: 0 16px;
      }
    }
    @media screen and (max-width: 1220px) {
      position: static;
      margin-top: 0;
    }
  }

  &__nav-row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  &__arrows {
    display: flex;
  }

  &__arrow {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #e6ccdc;
    color: #830051;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: #d8dfde;
    }

    &:first-child {
      margin-right: 18px;
    }
  }

  &__arrow-progress {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      flex-shrink: 0;
    }
    & circle {
      stroke-dasharray: 153;
      stroke-dashoffset: 153;
      transform: rotate(-90deg);
      transform-origin: center;
      stroke: #830051;
      stroke-width: 2px;
      r: 25px;
      cx: 50%;
      cy: 50%;
    }

    &_active {
      & circle {
        animation: loader 5s linear infinite both;
      }
    }
  }

  &__counter {
    margin-right: 25px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #830051;
  }
}

.first-slider {
  &__nav {
    position: relative;
    z-index: 2;
    margin-top: -80px;
    padding-bottom: 40px;

    @media screen and (max-width: 1219px) {
      margin-top: -100px;
    }
  }

  &__nav-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__arrows {
    display: flex;
  }

  &__arrow {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #e6ccdc;
    // color: rgb(222, 130, 60);
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #e6ccdc;
    }
    &:first-child {
      margin-right: 18px;
    }
  }

  &__arrow-progress {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      flex-shrink: 0;
    }
    & circle {
      stroke-dasharray: 153;
      stroke-dashoffset: 153;
      transform: rotate(-90deg);
      transform-origin: center;
      // stroke: #de823c;
      stroke-width: 2px;
      r: 25px;
      cx: 50%;
      cy: 50%;
    }

    &_active {
      & circle {
        animation: loader 5s linear infinite both;
      }
    }
  }

  &__counter {
    margin-right: 25px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #830051;
  }
}

@keyframes loader {
  from {
    stroke-dashoffset: 150;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>

<style lang="scss">
.colored-line {
  color: #000;
  margin-top: -25px;
  display: block;
  padding-top: 24px;
  border-top: 1px solid #000;
  z-index: 2;
  position: relative;
  @media screen and (max-width: 767px) {
    border-top: none;
  }
  &__gray {
    color: #b2b4b4;
    border-top: 1px solid #b2b4b4;
    @media screen and (max-width: 767px) {
      border-top: none;
    }
  }
  .slider-btn {
    max-width: fit-content;
    color: #ffffff;
    @media screen and (max-width: 767px) {
      max-width: none;
    }
  }
}
.top-block__title {
  &-green {
    // margin-top: 70px;
    // display: block;
    // max-width: 486px;
    color: #c4d600;
    @media screen and (max-width: 1199px) {
      // margin-top: 60px;
      // max-width: 320px;
      font-size: 35px;
      line-height: 40px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
</style>
